var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tenant-detail" },
    [
      _c("app-detail", { attrs: { opt: _vm.opt } }),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.optList } },
        _vm._l(_vm.optList1, function(item, idx) {
          return _c("el-table-column", {
            key: idx,
            attrs: { prop: item.prop, label: item.label, on: item.on },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    item.on
                      ? _c(
                          "a",
                          {
                            staticClass: "row-nav",
                            on: {
                              click: function($event) {
                                item.on(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      : item.type == "actions"
                        ? _c(
                            "div",
                            { staticClass: "row-action-box" },
                            _vm._l(item.opt.list, function(action, indexs) {
                              return _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row[item.key][indexs],
                                      expression: "scope.row[item.key][indexs]"
                                    }
                                  ],
                                  key: indexs,
                                  staticClass: "row-action-but",
                                  on: {
                                    click: function($event) {
                                      action.on(scope.row)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(action.label))]
                              )
                            }),
                            0
                          )
                        : _c("span", [_vm._v(_vm._s(scope.row[item.prop]))])
                  ]
                }
              }
            ])
          })
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单信息",
            visible: _vm.dialogLookNews0,
            width: "1100px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLookNews0 = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "alertTilte" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.orderDataList }
                },
                _vm._l(_vm.column1, function(item) {
                  return _c("el-table-column", {
                    key: item.prop,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      width: item.width
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "认证信息",
            visible: _vm.dialogLookNews1,
            width: "1100px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLookNews1 = $event
            }
          }
        },
        [
          _c("div", { staticClass: "alertTilte" }, [
            _c("div", [
              _c(
                "div",
                [
                  _c("app-overview", {
                    attrs: { title: _vm.actualTitle, list: _vm.actualList }
                  })
                ],
                1
              ),
              _vm.cache.getLS("userInfo")["roleType"] == "8"
                ? _c("div", { staticClass: "tenant-detail_idcardWarrper" }, [
                    _c("div", { staticClass: "tenant-detail_idcard" }, [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.idCardPic1 },
                        on: {
                          click: function($event) {
                            _vm.showBig(_vm.idCardPic1)
                          }
                        }
                      }),
                      _c("span", [_vm._v("身份证正面")])
                    ]),
                    _c("div", { staticClass: "tenant-detail_idcard" }, [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.idCardPic2 },
                        on: {
                          click: function($event) {
                            _vm.showBig(_vm.idCardPic2)
                          }
                        }
                      }),
                      _c("span", [_vm._v("身份证反面")])
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "缴费信息",
            visible: _vm.dialogLookNews2,
            width: "1100px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLookNews2 = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "alertTilte" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.payDataList }
                },
                _vm._l(_vm.column2, function(item) {
                  return _c("el-table-column", {
                    key: item.prop,
                    attrs: { prop: item.prop, label: item.label }
                  })
                }),
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "合同信息",
            visible: _vm.dialogLookNews3,
            width: "1100px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLookNews3 = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "alertTilte" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.payList }
                },
                _vm._l(_vm.column3, function(item) {
                  return _c("el-table-column", {
                    key: item.prop,
                    attrs: { prop: item.prop, label: item.label, on: item.on },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            item.on && scope.row.contractType == 2
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "row-nav row-nav-more",
                                    on: {
                                      click: function($event) {
                                        item.on(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("查看")]
                                )
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row[item.prop]))
                                ])
                          ]
                        }
                      }
                    ])
                  })
                }),
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "同住人",
            visible: _vm.dialogLookNews4,
            width: "1100px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLookNews4 = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "alertTilte" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.cohabitantList }
                },
                _vm._l(_vm.column4, function(item) {
                  return _c("el-table-column", {
                    key: item.prop,
                    attrs: { prop: item.prop, label: item.label },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            item.on
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "row-nav row-nav-more",
                                    on: {
                                      click: function($event) {
                                        item.on(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("删除同住人")]
                                )
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row[item.prop]))
                                ])
                          ]
                        }
                      }
                    ])
                  })
                }),
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }