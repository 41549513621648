<template>
  <div class="tenant-detail">
    <app-detail :opt="opt"></app-detail>
    <el-table :data="optList" style="width: 100%">
      <el-table-column
        v-for="(item,idx) in optList1"
        :key="idx"
        :prop="item.prop"
        :label="item.label"
        :on="item.on"
      >
        <template slot-scope="scope">
          <a class="row-nav" v-if="item.on" @click="item.on(scope.row)">查看</a>
           <div class="row-action-box" v-else-if="item.type=='actions'">
              <!-- 隐藏显示的逻辑 -->
              <span
                class="row-action-but"
                v-for="(action, indexs) in item.opt.list"
                :key="indexs"
                @click="action.on(scope.row)"
                v-show="scope.row[item.key][indexs]"
              >{{action.label}}</span>
              <!-- 显示的逻辑置灰 -->
              <!-- <span v-for="(action, indexs) in item.opt.list" :key="indexs">
                <span
                  class="row-action-but"
                  v-if="scope.row[item.key][indexs]==true"
                  @click="action.on(scope.row)"
                >{{action.label}}</span>
                <span
                  class="row-action-baclk"
                  v-else-if="scope.row[item.key][indexs]==false"
                >{{action.label}}</span>
              </span>-->
            </div>
          <span v-else>{{scope.row[item.prop]}}</span>
        </template>
      </el-table-column>
    </el-table>
     <!-- <el-pagination
      background
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 15, 20, 25]"
      :page-size="limit"
      :total="total"
      ></el-pagination> -->
    <el-dialog title="订单信息" :visible.sync="dialogLookNews0" width="1100px" :close-on-click-modal="false">
      <div class="alertTilte">
        <el-table :data="orderDataList" style="width: 100%">
          <el-table-column
            v-for="item in column1"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="认证信息" :visible.sync="dialogLookNews1" width="1100px" :close-on-click-modal="false">
      <div class="alertTilte">
        <div>
          <div>
            <app-overview :title="actualTitle" :list="actualList"></app-overview>
          </div>
          <div class="tenant-detail_idcardWarrper" v-if="cache.getLS('userInfo')['roleType'] == '8'">
            <div class="tenant-detail_idcard">
              <img :src="idCardPic1" @click="showBig(idCardPic1)" class="avatar" />
              <span>身份证正面</span>
            </div>
            <div class="tenant-detail_idcard">
              <img :src="idCardPic2" @click="showBig(idCardPic2)" class="avatar" />
              <span>身份证反面</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="缴费信息" :visible.sync="dialogLookNews2" width="1100px" :close-on-click-modal="false">
      <div class="alertTilte">
        <el-table :data="payDataList" style="width: 100%">
          <el-table-column
            v-for="item in column2"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="合同信息" :visible.sync="dialogLookNews3" width="1100px" :close-on-click-modal="false">
      <div class="alertTilte">
        <el-table :data="payList" style="width: 100%">
          <el-table-column
            v-for="item in column3"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :on="item.on"
          >
            <template slot-scope="scope">
              <a
                class="row-nav row-nav-more"
                v-if="item.on&& scope.row.contractType ==2"
                @click="item.on(scope.row)"
              >查看</a>
              <span v-else>{{scope.row[item.prop]}}</span>
              <!-- <a
                class="row-nav row-nav-more"
                v-if="item.on && ['0','2','3','4', '5'].indexOf(scope.row.state) == -1"
                @click="onTermination(scope.row)"
              >解约</a> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="同住人" :visible.sync="dialogLookNews4" width="1100px" :close-on-click-modal="false">
      <div class="alertTilte">
        <el-table :data="cohabitantList" style="width: 100%">
          <el-table-column
            v-for="item in column4"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
          >
           <template slot-scope="scope">
              <a
                class="row-nav row-nav-more"
                v-if="item.on"
                @click="item.on(scope.row)"
              >删除同住人</a>
              <span v-else>{{scope.row[item.prop]}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      type: "",
      contractId: "",
      userType: "",
      carUserId: "", //车主用户id
      actualTitle: "未认证",
      userId: "",
      idCardNo: "",
      actualList: [
        {
          label: "姓名",
          key: "realname",
          value: ""
        },
        {
          label: "性别",
          key: "gender",
          value: ""
        },
        {
          label: "身份证号",
          key: "idcardNoStr",
          value: ""
        },
        {
          label: "手机号",
          key: "phoneNum",
          value: ""
        }
      ],
      optList: [],
      optListUser: "",
      dialogLookNews0: false,
      dialogLookNews1: false,
      dialogLookNews2: false,
      dialogLookNews3: false,
      dialogLookNews4: false,
      optList1: [
        {
          prop: "roomName",
          label: "房屋名称"
        },
        {
          label: "公寓信息",
          prop: "查看",
          width: 150,
          key: "action",
          on: (row, index) => {
            _this.$router.push({
              path: "/main/tenant/Apartment/show/0",
              query: {
                id: row.roomId
              }
            });
          }
        },
        {
          label: "订单信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            _this.dialogLookNews0 = true;
            _this.getOrdeData(row);
          }
        },
        {
          label: "认证信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            console.log(row);
            _this.dialogLookNews1 = true;
            _this.getTenantDetail();
          }
        },
        // {
        //   label: "缴费信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     console.log(row);
        //     _this.dialogLookNews2 = true;
        //     _this.getTenantDetail(row.roomId);
        //   }
        // },
        {
          label: "合同信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            _this.dialogLookNews3 = true;
            _this.getData();
          }
        },
        {
          label: "同住人",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            _this.dialogLookNews4 = true;
            _this.contractType = row.contractType;
            _this.getCohabitantData(row.roomId);
          }
        },
        {
          label: "车辆进出记录",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            console.log(row);
            _this.$router.push({
              path: "/main/tenant/CarInAndOut/show/0",
              query: {
                id: _this.userId
              }
            });
          }
        },
        //  {
        //   prop: "leaseState",
        //   label: "租赁状态"
        // },
        {
          label: "操作",
          prop: "查看",
          key: "actions",
          type: "actions",
          opt: {
              list: [
                {
                  label: "强制解约",
                  on(row) {
                    _this.goTermination(row);
                  }
                },
                {
                  label: "删除同住人",
                  on(row) {
                    _this.dialogVisible = true;

                    const h = _this.$createElement;
                    _this
                      .$msgbox({
                        title: "确定提示",
                        message: h("p", null, [
                          h(
                            "p",
                            {
                              style: "font-size:14px;margin-left:98px"
                            },
                            "确定要删除同住人吗？"
                          )
                        ]),
                        showCancelButton: true,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消"
                      })
                      .then(action => {
                        var dto = {
                          roomId: row.id,
                          disable: true
                        };
                        _this
                          .post("landlord-service/room/isDisableRoom", dto)
                          .then(function() {
                            _this.$message({
                              showClose: true,
                              message: "删除同住人成功!",
                              type: "success"
                            });
                          });

                        _this.$refs.myList.onRefresh();
                      });
                  }
                },
                {
                  label: "同意退房",
                  on(row) {
                    _this.checkOut(row);
                  }
                },
              ]
            }
        }
      ],
      opt: {
        title: "房客详情",
        form: [
          {
            label: "姓名",
            key: "realName",
            isCol50: true
          },
          {
            label: "性别",
            key: "realNameStr",
            isCol50: true,
            type: "select",
            opt: {
              list: [
                {
                  value: 0,
                  label: "无"
                },
                {
                  value: 1,
                  label: "男"
                },
                {
                  value: 2,
                  label: "女"
                }
              ]
            }
          },
          {
            label: "身份证号码",
            key: "idcardNoStr",
            isCol50: true
          },
          {
            label: "电话",
            key: "phone",
            isCol50: true
          },
          {
            label: "紧急联系电话",
            key: "emergencyPhoneNum",
            isCol50: true
          },
          {
            label: "入住时间",
            key: "starDate",
            isCol50: true
          },
          {
            label: "结束时间",
            key: "endDate",
            isCol50: true
          },
          {
            label: "车牌号码",
            key: "carNo",
            isCol50: true
          }
        ],
        get(opt) {
          let dto = {};
          if (_this.$route.query.type == 0) {
            dto.contractId = opt.id;
            _this.contractId=opt.id;
          } else {
            dto.id = opt.id;
          }

          let url =
            _this.$route.query.type == 0
              ? "tenant-service/leasecontract/queryTalentInfo"
              : "landlord-service/shortRent/getShortRent";
          _this.post(url, dto).then(data => {
            // 保存租赁周期
            if (_this.$route.query.type == 0) {
              _this.leasecycle =
                data.starDate.slice(0, 10) + "-" + data.endDate.slice(0, 10);
            } else {
              data.starDate = data.startDate;
              _this.leasecycle =
                data.startDate.slice(0, 10) + "-" + data.endDate.slice(0, 10);
            }
            _this.opt.form.forEach(item=>{
              
              if(item.key=='idcardNoStr' || item.key=='otherNo'){
                
                if(_this.$route.query.type == 0){
                   if(data.idCardType==7){
                      item.label='学号';
                      item.key='otherNo';
                      data.otherNo=data.otherNo.substring(14,data.otherNo.length);
                   }
                   else if(data.idCardType==1){
                     item.label='身份证号';
                     item.key='idcardNoStr';
                   }
                   else{
                     item.label='证件号码';
                     item.key='idcardNoStr';
                   }
                }
                else if(_this.$route.query.type == 1){
                  if(data.idType==7){
                      item.label='学号';
                      item.key='otherNo';
                      data.otherNo=data.idCardNo.substring(14,data.idCardNo.length);
                  }
                  else if(data.idType==1){
                    item.label='身份证号';
                    item.key='idcardNoStr';
                  }
                  else{
                     item.label='证件号码';
                     item.key='idcardNoStr';
                   }
                }
                
              }
            })
            if (_this.cache.getLS("userInfo")["roleType"] != "8") {
            data.idcardNoStr = data.idCardNo
              ? data.idCardNo.substring(0, 2) +
                "******" +
                data.idCardNo.substring(
                  data.idCardNo.length - 2,
                  data.idCardNo.length
                )
              : "";
            data.phone = data.phone
              ? data.phone.substring(0, 3) +
                "****" +
                data.phone.substring(7, data.phone.length)
              : "";
            data.emergencyPhoneNum = data.emergencyPhoneNum
              ? data.emergencyPhoneNum.substring(0, 3) +
                "******" +
                data.emergencyPhoneNum.substring(
                  9,
                  data.emergencyPhoneNum.length
                )
              : "";
            }
            else{
              data.idcardNoStr = data.idCardNo;
            }
            data.realNameStr =
              data.gender == 0 ? "无" : ["男", "女"][data.gender - 1];
            _this.userId = data.userId;
            _this.idCardNo = data.idCardNo;
            opt.cb(data);
            _this.optList = data.roomList;
            _this.optList.forEach(optItem=>{
              optItem.actions = [false, false, false];
              if((optItem.rentId && _this.$route.query.type != 0) || (optItem.contractId && _this.$route.query.type == 0)){
                optItem.actions = [true, false, false];
              }
              optItem.leaseState = [
            "未支付",
            "待入住",
            "在租",
            "退房中",
            "已退房",
            "",
            "",
            "取消预订",
            "预定中"
          ][optItem.state];
            })
          });
        }
      },
      column1: [],
      shortOrderColumn:  [
        {
          prop: "orderNo",
          label: "订单编号"
        },
        {
          prop: "startTime",
          label: "入住时间"
        },
        {
          prop: "endTime",
          label: "截止时间"
        },
        {
          prop: "payDate",
          label: "实缴时间"
        },
        {
          prop: "orderName",
          label: "订单名称"
        },
        {
          prop: "rentalFee",
          label: "租金",
          width: 80
        },
        {
          prop: "depositFee",
          label: "押金",
          width: 80
        },
        {
          prop: "orderAmount",
          label: "总金额",
          width: 100
        },
        {
          prop: "payState",
          label: "订单状态",
          width: 80
        }
      ],
      longOrderColumn: [
        {
          prop: "orderNo",
          label: "订单编号"
        },
        {
          prop: "payRentalTerm",
          label: "应缴日期"
        },
        {
          prop: "payDate",
          label: "实缴日期"
        },
        {
          prop: "orderName",
          label: "订单名称"
        },
        {
          prop: "rentalFee",
          label: "租金",
          width: 80
        },
        {
          prop: "depositFee",
          label: "押金",
          width: 80
        },
        {
          prop: "otherFee",
          label: "物业费",
          width: 80
        },
        {
          prop: "electric",
          label: "电费",
          width: 80
        },
        {
          prop: "coldWater",
          label: "冷水费",
          width: 80
        },
        {
          prop: "hotWater",
          label: "热水费",
          width: 80
        },
        {
          prop: "orderAmount",
          label: "总金额",
          width: 100
        },
        {
          prop: "payState",
          label: "订单状态",
          width: 80
        }
      ],
      payDataList: [],
      payList: [],
      column2: [],
      originalColumn2: [
        {
          prop: "startDate",
          label: "起租日期"
        },
        {
          prop: "endDate",
          label: "到期日期"
        },
        {
          prop: "reconDate",
          label: "账单生成日期"
        },
        {
          prop: "payRentalTerm",
          label: "收租日期"
        },
        {
          prop: "rentalFee",
          label: "租金"
        },
        {
          prop: "deposit",
          label: "押金"
        }
      ],
      column3: [
        {
          prop: "contractTypeStr",
          label: "租赁合同类型"
        },
        {
          prop: "createdDate",
          label: "签约时间"
        },
        {
          prop: "id",
          label: "合同编号"
        },
        {
          prop: "apartmentName",
          label: "房屋详情"
        },
        {
          prop: "count",
          label: "合同期限"
        },
        {
          prop: "endDateStr",
          label: "到期日期"
        },
        {
          prop: "rentalFee",
          label: "月租金"
        },
        {
          prop: "stateStr",
          label: "合同状态"
        },
        {
          label: "操作",
          prop: "查看",
          key: "actions",
          type: "actions",
          on: (row, index) => {
            _this.dialogLookNews3 = false;
            if(row["downloadUrl"]){
               var tempwindow=window.open('_blank');
                tempwindow.location=row["downloadUrl"];
                // window.location.href=row["downloadUrl"];
            }
          }
        }
      ],
      column4: [
        {
          prop: "cohabitantName",
          label: "同住人姓名"
        },
        {
          prop: "gender",
          label: "性别"
        },
        {
          prop: "idcardNoStr",
          label: "身份证号"
        },
        {
          prop: "phoneNum",
          label: "手机号码"
        },
        {
          prop: "startDate",
          label: "同住起始日期"
        },
        {
          prop: "roomNo",
          label: "同住房号"
        },
         {
          label: "操作",
          prop: "查看",
          key: "action",
          type: "action",
          on(row) {
                    const h = _this.$createElement;
                    _this
                      .$msgbox({
                        title: "删除同住人",
                        message: h("p", null, [
                           h(
                      "span",
                      {
                        style: "color: #333;font-size: 16px;margin-left:98px"
                      },
                      "确定要删除同住人吗？"
                    ),
                          h(
                            "p",
                            {
                              style: "font-size:14px;margin-left:98px"
                            },
                            "请先与房客确认删除同住人"
                          )
                        ]),
                        showCancelButton: true,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消"
                      })
                      .then(action => {
                        var dto = {
                          contractId: row.contractId,
                          userId: row.userId,
                        };
                        _this
                          .post("/tenant-service/leaseroomuser/delCohabitant", dto)
                          .then(function() {
                            _this.$message({
                              showClose: true,
                              message: "删除同住人成功!",
                              type: "success"
                            });
                            _this.getCohabitantData(row.roomInfo.roomid);
                          });
                      });
                  }
        }
      ],
      authenticationData: [],
      orderDataList: [],
      cohabitantList: [], // 同住人信息
      leasecycle: "", // 租赁周期
      contractId: "", //合同id
      roomId: "", //房间id
      rooTypeList: [], //房型列表
      facilities: [], //配套标签列表
      apartmentList: [], // 房源列表
      idCardPic1: "",
      idCardPic2: "",
      dialogVisible: false,
      dialogImageUrl: "",
      carRecordDataList: [], //车辆进出信息
      currentPage: 1,
      skip: 1,
      limit: 5,
      total: 0,
    };
  },
  created() {
    this.carUserId = this.$route.query.userid || "";
    // this.roomId = this.$route.query.roomid || "";
    this.userType = this.cache.getLS("userInfo")["roleType"];
  },
  activated() {
    console.log("tenant-detail created!!");
    this.type = this.$route.query.type;
    this.optList = [];
    // this.roomId = this.$route.query.roomid || "";
    this.userType = this.cache.getLS("userInfo")["roleType"];
    let _this=this;
    // console.log(this.type)
    if(this.type == 0) {
     this.column1 = this.longOrderColumn;
    }else {
      this.column1 = this.shortOrderColumn;
    }
    //长租显示物业费
    if (this.type == '0') {
      let _col = this.originalColumn2.concat([
        {
          prop: "propertyFee",
          label: "物业费"
        }
      ]);
      this.column2 = JSON.parse(JSON.stringify(_col));
    } else if (this.type == 1) {
      this.column2 = JSON.parse(JSON.stringify(this.originalColumn2));
    }
    // userType为1 admin  userType为8 公安账号   type为1短租
    if (this.userType === "1" && this.type != '1') {
      this.optList1 = [
        {
          prop: "roomName",
          label: "房屋名称"
        },
        {
          label: "公寓信息",
          prop: "查看",
          width: 150,
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/Apartment/show/0",
              query: {
                id: row.roomId
              }
            });
          }
        },
        {
          label: "订单信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews0 = true;
            this.getOrdeData(row);
          }
        },
        {
          label: "认证信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews1 = true;
            this.getTenantDetail();
          }
        },
        // {
        //   label: "缴费信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews2 = true;
        //     this.getTenantDetail(row.roomId);
        //   }
        // },
        {
          label: "合同信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews3 = true;
            this.getData(row);
          }
        },
        {
          label: "同住人",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews4 = true;
            this.contractType = row.contractType;
            this.getCohabitantData(row.roomId);
          }
        },
        {
          label: "车辆进出记录",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/CarInAndOut/show/0",
              query: {
                id: this.userId
              }
            });
          }
        },
        //  {
        //   prop: "leaseState",
        //   label: "租赁状态"
        // },
          {
          label: "操作",
          prop: "查看",
          key: "actions",
          type: "actions",
          opt: {
              list: [
                {
                  label: "强制解约",
                  on(row) {
                    _this.goTermination(row);
                  }
                },
                {
                  label: "删除同住人",
                  on(row) {
                    const h = _this.$createElement;
                    _this
                      .$msgbox({
                        title: "确定提示",
                        message: h("p", null, [
                          h(
                            "p",
                            {
                              style: "font-size:14px;margin-left:98px"
                            },
                            "确定要删除同住人吗？"
                          )
                        ]),
                        showCancelButton: true,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消"
                      })
                      .then(action => {
                        var dto = {
                          roomId: row.id,
                          disable: true
                        };
                        _this
                          .post("landlord-service/room/isDisableRoom", dto)
                          .then(function() {
                            _this.$message({
                              showClose: true,
                              message: "删除同住人成功!",
                              type: "success"
                            });
                          });

                        _this.$refs.myList.onRefresh();
                      });
                  }
                },
                {
                  label: "同意退房",
                  on(row) {
                    _this.checkOut(row);
                  }
                },
              ]
            }
        }
      ];
    } 
    else if (this.userType === "8" && this.type != '1') {
      this.optList1 = [
        {
          prop: "roomName",
          label: "房屋名称"
        },
        {
          label: "公寓信息",
          prop: "查看",
          width: 150,
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/Apartment/show/0",
              query: {
                id: row.roomId
              }
            });
          }
        },
        {
          label: "订单信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews0 = true;
            this.getOrdeData(row);
          }
        },
        {
          label: "认证信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews1 = true;
            this.getTenantDetail();
          }
        },
        // {
        //   label: "缴费信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews2 = true;
        //     this.getTenantDetail(row.roomId);
        //   }
        // },
        {
          label: "合同信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews3 = true;
            this.getData(row);
          }
        },
        {
          label: "同住人",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews4 = true;
            this.contractType = row.contractType;
            this.getCohabitantData(row.roomId);
          }
        },
        {
          label: "车辆进出记录",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/CarInAndOut/show/0",
              query: {
                id: this.userId
              }
            });
          }
        },
        //  {
        //   prop: "leaseState",
        //   label: "租赁状态"
        // },
      ];
    } 
    else if (this.userType === "1" && this.type == '1') {
      this.optList1 = [
        {
          prop: "roomName",
          label: "房屋名称"
        },
        {
          label: "公寓信息",
          prop: "查看",
          width: 150,
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/Apartment/show/0",
              query: {
                id: row.roomId
              }
            });
          }
        },
        {
          label: "订单信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews0 = true;
            this.getOrdeData(row);
          }
        },
        {
          label: "认证信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews1 = true;
            this.getTenantDetail();
          }
        },
        // {
        //   label: "缴费信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews2 = true;
        //     this.getTenantDetail(row.roomId);
        //   }
        // },
        // {
        //   label: "合同信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews3 = true;
        //     this.getData(row);
        //   }
        // },
        {
          label: "同住人",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews4 = true;
            this.contractType = row.contractType;
            this.getCohabitantData(row.roomId);
          }
        },
        {
          label: "车辆进出记录",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/CarInAndOut/show/0",
              query: {
                id: this.userId
              }
            });
          }
        },
        //  {
        //   prop: "leaseState",
        //   label: "租赁状态"
        // },
          {
          label: "操作",
          prop: "查看",
          key: "actions",
          type: "actions",
          opt: {
              list: [
                {
                  label: "强制解约",
                  on(row) {
                    _this.goTermination(row);
                  }
                },
                {
                  label: "删除同住人",
                  on(row) {
                    _this.dialogVisible = true;

                    const h = _this.$createElement;
                    _this
                      .$msgbox({
                        title: "确定提示",
                        message: h("p", null, [
                          h(
                            "p",
                            {
                              style: "font-size:14px;margin-left:98px"
                            },
                            "确定要删除同住人吗？"
                          )
                        ]),
                        showCancelButton: true,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消"
                      })
                      .then(action => {
                        var dto = {
                          roomId: row.id,
                          disable: true
                        };
                        _this
                          .post("landlord-service/room/isDisableRoom", dto)
                          .then(function() {
                            _this.$message({
                              showClose: true,
                              message: "删除同住人成功!",
                              type: "success"
                            });
                          });

                        _this.$refs.myList.onRefresh();
                      });
                  }
                },
                {
                  label: "同意退房",
                  on(row) {
                   _this.checkOut(row);
                  }
                },
              ]
            }
        }
      ];
    }
    else if (this.userType === "8" && this.type == '1') {
      this.optList1 = [
        {
          prop: "roomName",
          label: "房屋名称"
        },
        {
          label: "公寓信息",
          prop: "查看",
          width: 150,
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/Apartment/show/0",
              query: {
                id: row.roomId
              }
            });
          }
        },
        {
          label: "订单信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews0 = true;
            this.getOrdeData(row);
          }
        },
        {
          label: "认证信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews1 = true;
            this.getTenantDetail();
          }
        },
        // {
        //   label: "缴费信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews2 = true;
        //     this.getTenantDetail(row.roomId);
        //   }
        // },
        // {
        //   label: "合同信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews3 = true;
        //     this.getData(row);
        //   }
        // },
        {
          label: "同住人",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews4 = true;
            this.contractType = row.contractType;
            this.getCohabitantData(row.roomId);
          }
        },
        {
          label: "车辆进出记录",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/CarInAndOut/show/0",
              query: {
                id: this.userId
              }
            });
          }
        },
        //  {
        //   prop: "leaseState",
        //   label: "租赁状态"
        // },
      ];
    }
    else if(this.userType != "1" && this.userType != "8" && this.type != '1'){
      this.optList1 = [
        {
          prop: "roomName",
          label: "房屋名称"
        },
        {
          label: "公寓信息",
          prop: "查看",
          width: 150,
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/Apartment/show/0",
              query: {
                id: row.roomId
              }
            });
          }
        },
        {
          label: "订单信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews0 = true;
            this.getOrdeData(row);
          }
        },
        // {
        //   label: "认证信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews1 = true;
        //     this.getTenantDetail();
        //   }
        // },
        // {
        //   label: "缴费信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews2 = true;
        //     this.getTenantDetail(row.roomId);
        //   }
        // },
         {
          label: "合同信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews3 = true;
            this.getData(row);
          }
        },
        {
          label: "同住人",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews4 = true;
            this.contractType = row.contractType;
            this.getCohabitantData(row.roomId);
          }
        },
        {
          label: "车辆进出记录",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/CarInAndOut/show/0",
              query: {
                id: this.userId
              }
            });
          }
        },
        //  {
        //   prop: "leaseState",
        //   label: "租赁状态"
        // },
         {
          label: "操作",
          prop: "查看",
          key: "actions",
          type: "actions",
          opt: {
              list: [
                {
                  label: "强制解约",
                  on(row) {
                   _this.goTermination(row);
                  }
                },
                {
                  label: "删除同住人",
                  on(row) {
                    _this.dialogVisible = true;

                    const h = _this.$createElement;
                    _this
                      .$msgbox({
                        title: "确定提示",
                        message: h("p", null, [
                          h(
                            "p",
                            {
                              style: "font-size:14px;margin-left:98px"
                            },
                            "确定要删除同住人吗？"
                          )
                        ]),
                        showCancelButton: true,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消"
                      })
                      .then(action => {
                        var dto = {
                          roomId: row.id,
                          disable: true
                        };
                        _this
                          .post("landlord-service/room/isDisableRoom", dto)
                          .then(function() {
                            _this.$message({
                              showClose: true,
                              message: "删除同住人成功!",
                              type: "success"
                            });
                          });

                        _this.$refs.myList.onRefresh();
                      });
                  }
                },
                {
                  label: "同意退房",
                  on(row) {
                    _this.checkOut(row);
                  }
                },
              ]
            }
        }
      ];
    }
    else {
      this.optList1 = [
        {
          prop: "roomName",
          label: "房屋名称"
        },
        {
          label: "公寓信息",
          prop: "查看",
          width: 150,
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/Apartment/show/0",
              query: {
                id: row.roomId
              }
            });
          }
        },
        {
          label: "订单信息",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews0 = true;
            this.getOrdeData(row);
          }
        },
        // {
        //   label: "认证信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews1 = true;
        //     this.getTenantDetail();
        //   }
        // },
        // {
        //   label: "缴费信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews2 = true;
        //     this.getTenantDetail(row.roomId);
        //   }
        // },
        //  {
        //   label: "合同信息",
        //   prop: "查看",
        //   key: "action",
        //   on: (row, index) => {
        //     this.dialogLookNews3 = true;
        //     this.getData(row);
        //   }
        // },
        {
          label: "同住人",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.dialogLookNews4 = true;
            this.contractType = row.contractType;
            this.getCohabitantData(row.roomId);
          }
        },
        {
          label: "车辆进出记录",
          prop: "查看",
          key: "action",
          on: (row, index) => {
            this.$router.push({
              path: "/main/tenant/CarInAndOut/show/0",
              query: {
                id: this.userId
              }
            });
          }
        },
        //  {
        //   prop: "leaseState",
        //   label: "租赁状态"
        // },
          {
          label: "操作",
          prop: "查看",
          key: "actions",
          type: "actions",
          opt: {
              list: [
                {
                  label: "强制解约",
                  on(row) {
                   _this.goTermination(row);
                  }
                },
                {
                  label: "删除同住人",
                  on(row) {
                    _this.dialogVisible = true;
                    const h = _this.$createElement;
                    _this
                      .$msgbox({
                        title: "确定提示",
                        message: h("p", null, [
                          h(
                            "p",
                            {
                              style: "font-size:14px;margin-left:98px"
                            },
                            "确定要删除同住人吗？"
                          )
                        ]),
                        showCancelButton: true,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消"
                      })
                      .then(action => {
                        var dto = {
                          roomId: row.id,
                          disable: true
                        };
                        _this
                          .post("landlord-service/room/isDisableRoom", dto)
                          .then(function() {
                            _this.$message({
                              showClose: true,
                              message: "删除同住人成功!",
                              type: "success"
                            });
                          });

                        _this.$refs.myList.onRefresh();
                      });
                  }
                },
                {
                  label: "同意退房",
                  on(row) {
                    _this.checkOut(row);
                  }
                },
              ]
            }
        }
      ];
    }
    if(this.userType === "8"){
      this.column3=[ {
          prop: "contractTypeStr",
          label: "租赁合同类型"
        },
        {
          prop: "createdDate",
          label: "签约时间"
        },
        {
          prop: "id",
          label: "合同编号"
        },
        {
          prop: "apartmentName",
          label: "房屋详情"
        },
        {
          prop: "count",
          label: "合同期限"
        },
        {
          prop: "endDateStr",
          label: "到期日期"
        },
        {
          prop: "rentalFee",
          label: "月租金"
        },
        {
          prop: "stateStr",
          label: "合同状态"
        },];
      // this.optList1.splice(this.optList1.length-1,1);
      this.column4=[ {
          prop: "cohabitantName",
          label: "同住人姓名"
        },
        {
          prop: "gender",
          label: "性别"
        },
        {
          prop: "idcardNoStr",
          label: "身份证号"
        },
        {
          prop: "phoneNum",
          label: "手机号码"
        },
        {
          prop: "startDate",
          label: "同住起始日期"
        },
        {
          prop: "roomNo",
          label: "同住房号"
        },];
    }
  },
  methods: {
    // 获取订单信息
    getOrdeData(data) {
      let dto = {
        orderType: this.type,
        userId: this.userId,
        roomId: data.roomId,
        idCardNo: this.idCardNo,
        // contractId: this.contractId,
      };
      this.post("finance-service/leaseOrder/searchLeaseOrder", dto).then(
        res => {
          res = res.reverse();
          res.forEach(item => {
            for (var key in item) {
              if (key == "payState") {
                //1： 未支付 2：支付中 3：支付成功 4：支付失败 5: 订单取消 6：订单失效（首单30分钟未支付）
                item[key] = [
                  "未支付",
                  "支付中",
                  "已支付",
                  "支付失败",
                  "订单取消",
                  "订单失效",
                  "退款中"
                ][item.payState - 1];
              }
            }
            if (item.waterElectrics && item.waterElectrics[0]) {
              item.electric = item.waterElectrics[0].electric
                ? "￥" + item.waterElectrics[0].electric
                : "";
              item.coldWater = item.waterElectrics[0].coldWater
                ? "￥" + item.waterElectrics[0].coldWater
                : "";
              item.hotWater = item.waterElectrics[0].hotWater
                ? "￥" + item.waterElectrics[0].hotWater
                : "";
            }
          });

          this.orderDataList = res;
        }
      );
    },
    // 获取认证信息
    getTenantDetail() {
      let _this=this;
      this.post("user-service/tenant/getTenantDetail", {
        userId: this.userId
      }).then(res => {
        this.authenticationData = res;
        if (_this.cache.getLS("userInfo")["roleType"] != "8") {
        res.idcardNoStr = res.idcardNo
          ? res.idcardNo.substring(0, 2) +
            "******" +
            res.idcardNo.substring(res.idcardNo.length - 2, res.idcardNo.length)
          : "";
        res.phoneNum = res.phoneNum
          ? res.phoneNum.substring(0, 3) +
            "****" +
            res.phoneNum.substring(7, res.phoneNum.length)
          : "";
        }
        else{
          res.idcardNoStr = res.idcardNo;
        }
        let keys = [];
        for (var key in res) {
          keys.push(key);
        }
        this.actualList.forEach(item => {
          if (keys.indexOf(item["key"]) != -1) {
            item.value = res[keys[keys.indexOf(item["key"])]];
          }
          if (item.key == "gender" && item.value) {
            item.value = item.value == 1 ? "男" : "女";
          }
        });
        if (res.idCardPic1) {
          this.idCardPic1 = res.idCardPic1;
        }
        if (res.idCardPic2) {
          this.idCardPic2 = res.idCardPic2;
        }
        if (res.authState =='3') {
          this.actualTitle = "未认证";
        } else {
          this.actualTitle = "已认证";
        }
      });
    },
    // 获取缴费信息
    getPayData(roomId) {
      let dto = {
        roomId: roomId,
        userid: this.userId
      };
      if (this.type == "1") {
        dto = {
          roomId: roomId,
          userId: this.userId
        };
      }
      let url =
        this.type == "0"
          ? "tenant-service/leasecontract/queryPayFee"
          : "landlord-service/shortRentOrder/queryPayFee";
      this.post(url, dto).then(res => {
        this.payDataList = res;
      });
    },
    goTermination(val){
      if(val.rentId && this.type == '1'){//短租
         this.$router.push("/main/tenant/shortTermination/show?rentId="+val.rentId);
      }
      if(val.contractId && this.type == '0'){
          this.$router.push("/main/tenant/rowHousesTermination/show?contractId="+val.contractId);
      }
    },
    // 获取合同信息
    getData(val,flag) {
      let dto = {
        pageNum: 1,
        pageSize: 9999,
        userid: this.userId,
        roomId: val.roomId
      };
      this.post(
        "/tenant-service/v1/lease-info/query/get-tenant-contract-info",
        dto
      ).then(res => {
        res.forEach(item => {
          item.stateStr = [
            "待支付",
            "签约成功",
            "签约失败",
            "合同到期",
            "已解约",
            "强制解约"
          ][item.state];
          item.startDate = moment(item.startDate).format("YYYY-MM-DD");
          item.endDate = moment(item.endDate).format("YYYY-MM-DD");
          if (
            item.startDate ===
            moment(item.startDate)
              .startOf("month")
              .format("YYYY-MM-DD")
          ) {
            item.count = moment(item.endDate).diff(item.startDate, "month") + 1;
          } else {
            if (
              moment(item.startDate).format("DD") == "31" &&
              moment(item.endDate).format("DD") == "30"
            ) {
              item.count =
                moment(item.endDate).diff(item.startDate, "month") + 1;
            } else {
              item.count = moment(item.endDate).diff(item.startDate, "month");
            }
          }
          item.endDateStr = moment(item.endDate).format("YYYY-MM-DD");
          for (var key in item) {
            if (key == "payState") {
              item[key] = item.payState == 3 ? "已缴费" : "未缴费";
            }
            if (key == "gender" && item[key]) {
              item[key] = ["男", "女"][item[key] - 1];
            }
            if (key == "apartmentName" && item[key]) {
              item[key] = `${item.city}${item[key]}房源${item.buildNo}栋${
                item.unitNo ? item.unitNo + "单元" : ""
              }${item.roomNo}房`;
            }
          }
          item.contractTypeStr = [
            "线下纸质合同",
            "CA电子合同",
          ][item.contractType-1];
        });
        this.payList = res;
        if(flag){
          this.cache.set("tenantDetailContract", this.payList[0]);
          this.$router.push("/main/tenant/termination/show");
        }
      });
    },
    // 获取同住人信息
    getCohabitantData(roomId) {
      let dto = {
        userId: this.userId,
        contractType: this.contractType,
        roomId: roomId
      };
      let url =
        this.type == "0"
          ? "tenant-service/leaseroomuser/myCohabitantBack"
          : "tenant-service/leaseroomuser/selectCohabitantByRentId";
          let _this=this;
      this.post(url, dto).then(res => {
        let label='证件号码';
        this.opt.form.forEach(item=>{
              if(item.key=='idcardNoStr' || item.key=='otherNo'){
                  label=item.label;
              }
            })
           this.column4.forEach(it=>{
             if(it.prop=='idcardNoStr'){
               it.label=label;
             }
          })
        res.forEach(item => {
          for (var key in item) {
            item.roomNo = item.roomInfo.roomNo;
            if (_this.cache.getLS("userInfo")["roleType"] != "8" && item.cardType!=7) {
            item.idcardNoStr = item.idCardNo
              ? item.idCardNo.substring(0, 2) +
                "******" +
                item.idCardNo.substring(
                  item.idCardNo.length - 2,
                  item.idCardNo.length
                )
              : "";
            item.phoneNum = item.phoneNum
              ? item.phoneNum.substring(0, 3) +
                "****" +
                item.phoneNum.substring(7, item.phoneNum.length)
              : "";
              if(label='学号')//如果房客是学生，同住人填了身份证号，显示--
              {
                item.idcardNoStr='--'
              }
            }
            else{
              item.idcardNoStr = item.idCardNo;
            }
            if (key == "gender") {
              item[key] = ["男", "女"][item.gender - 1];
            }
          }
          
        //   {
        //   prop: "idcardNoStr",
        //   label: "身份证号"
        // },
        });
        this.cohabitantList = res;
        
        
      });
    },
    //放大图片
    showBig(src) {
      this.dialogImageUrl = src;
      this.dialogVisible = true;
    },
      //改变表格展示条数
    handleSizeChange(val) {
      // this.skip = 1;
      this.limit = val;
      this.onSearch();
    },
    //表格翻页
    handleCurrentChange(val) {
      this.skip = val;
      this.onSearch();
    },
    /**
     * 强制解约
     */
    onTermination(row) {
      this.dialogLookNews3 = false;
      this.cache.set("tenantDetailContract", row);
      this.$router.push("/main/tenant/termination/show");
    },
     /**
     * 同意退房
     */
    checkOut(row){
      let _this=this;
       row.rentalStyle = row.rentalStyle == "长租" ? "0" : "1";
                row.checkoutSum =
                  row.checkoutSum == "" || row.checkoutSum == undefined
                    ? "0.00"
                    : row.checkoutSum;
                _this.$router.push(
                  "/main/CheckOut/AddDetail/edit/0" +
                    "?realname=" +
                    row.realname +
                    "&address=" +
                    row.address +
                    "&quitDate=" +
                    row.quitDate +
                    "&checkoutSum=" +
                    row.checkoutSum +
                    "&rentalStyle=" +
                    row.rentalStyle +
                    "&roomId=" +
                    row.roomId +
                    "&shortRentId=" +
                    row.shortRentId
                );
    },
  }
};
</script>
<style lang="scss">
.tenant-detail {
  .el-tabs {
    margin: 0 auto;
    margin-top: 10px;
    max-width: 900px;
  }
}
.shenfen {
  margin-right: 81px;
  margin-left: 99px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #30BAC1;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  cursor: pointer;
}
.row-nav {
  cursor: pointer;
  color: #30BAC1;
}
.row-nav-more {
  margin-left: 10px;
}
.tenant-detail_idcardWarrper {
  display: flex;
  justify-content: space-around;
}
.tenant-detail_idcard span {
  margin-top: 7px;
  display: block;
  text-align: center;
}
.row-action-but {
    color: #30BAC1;
    cursor: pointer;
    margin-right: 10px;
  }
</style>
<style lang="scss" scoped>
.app-list {
  height: 590px !important;
}
</style>


